import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire UX Researchers`,
    text: "Your UX Research Consultant",
    content: "Hire UX Researchers from Octet to deeply understand your users, uncover their pain points, and provide data-driven insights to inform your design and development decisions.",
    list: [
      'Seasoned UX Researcher',
      'Flexible Hiring Options',
      'Time-Zone Friendly',
      'User-Centric Mindset'
    ]
  };
  const ourData = {
    title: "Client’s Experiences With Our UX Research Consultant",
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Improve User Experiences <span class='h1-span'>With Top UX Researcher</span>",
    content: [
      {
        title: "8+ Team of UX Consultants",
        text: "With a team of 8+ experienced user experience research consultants, we bring diverse skills and expertise to tackle your complex UX challenges.",
      },
      {
        title: "200+ UX Projects Completed",
        text: "Our top UX researchers have a proven track record, having completed over 200 successful UX projects across industries, driving measurable improvements in user satisfaction.",
      },
      {
        title: "10k+ User Interview Conducted",
        text: "Through 10k+ user interviews, our user experience research consultants have gained deep insights into user behaviors, needs, and pain points.",
      },
      {
        title: "200+ Usability Test Sessions",
        text: "Our UX consultants have facilitated 200+ comprehensive usability test sessions, providing you with data-driven feedback to optimize your digital experiences.",
      },
    ],
  };
  const commonContent1 = {
    title: "Hire UX Researcher to Drive Unmatched Outcomes",
    content: [
      {
        title: "Discover Hidden User Needs",
        text: "Our user experience research consultants utilize advanced research techniques to uncover unmet user needs and pain points, enabling you to develop innovative solutions.",
      },
      {
        title: "Drive Data-Driven Decisions",
        text: "Our user research consultant provides evidence-based recommendations to guide your design decisions, backed by in-depth user insights and behavioral analytics.",
      },
      {
        title: "Reduce Time-to-Market",
        text: "Our user experience research consultants help you bring your product to market faster by identifying and addressing user issues in the initial stages of the design process.",
      },
      {
        title: "Maximize ROI",
        text: "Our UX consultants help you optimize your design investments by ensuring your solutions are tailored to meet your target users' needs and preferences.",
      },
      {
        title: "Optimise Touchpoint Experiences",
        text: "By thoroughly analyzing the user journey, our UX research consultant identifies friction points & optimizes touchpoints to deliver a seamless and delightful customer experience.",
      },
      {
        title: "Boost Engagement and Retention",
        text: "Our UX research experts help you create engaging interfaces and features that foster long-term user loyalty and retention by leveraging user research capabilities.",
      },
    ],
  };
  const Questions = [
    "Which companies should Hire UX researchers?",
    "Why should I hire user experience researchers from Octet?",
    "What are the steps to Hire UX researcher consultant from Octet?",
    "How will I communicate with my UX researchers?",
    'How much does it cost to hire user experience researchers from Octet?'
  ];
  const faqData = [
    {
      para: [
        `Companies across various industries can benefit by hiring experienced user experience research consultants.`,
        `E-commerce businesses, SaaS providers, mobile app developers, fintech firms, healthcare organizations, enterprise software companies, and startups can leverage UX research consultants' expertise to offer users exceptional digital experiences.`,
        `UX research experts can help enhance product intuitiveness, create seamless user experiences, design user-centric solutions, and simplify complex interfaces by leveraging advanced research methodologies, data-driven insights, and a collaborative approach.`
      ],
    },
    {
      para: [
        `Our UX researchers bring expertise and a proven track record for delivering impactful results.`,
        `With a team of seasoned user experience professionals, we provide data-driven insights to optimize your digital experiences, enhance usability, and drive measurable improvements in user satisfaction and conversion rates.`,
        `By hiring UX researchers from Octet, you can gain a competitive advantage, accelerate your time-to-market, and maximize the ROI of your design investments.`
      ],
    },
    {
      para: [
        `You can hire UX researchers in 3 easy steps:`,
      ],
      list: [
        `<span><h4 class="inline">Share Your Requirement:</h4></span> Share your project needs and details with us, outlining your objectives and expectations so we can match you with the top UX researcher.`,
        `<span><h4 class="inline">Choose Your UX Research Consultant:</h4></span> Interview and hire UX researcher based on your business goals, assessing their experience, expertise, and compatibility.`,
        `<span><h4 class="inline">Get Started:</h4></span> Finalize and onboard your chosen user experience research consultant and collaborate to implement effective user research strategies and achieve your desired outcomes.`
      ]
    },
    {
      para: [
        `Once you onboard your hired UX researcher, you can communicate with them through various channels, such as email, phone calls, or video conferencing, for real-time conversations.`,
      ],
    },
    {
        para: [
            `We offer flexible pricing options for hiring our experienced user experience researchers, with costs varying based on project duration, research activities, deliverables, and the level of expertise required.`,
            `We would request you to <a href="/contact-us/" title="contact us">contact us</a> to get a detailed cost estimate and to discuss specific requirements to ensure maximum value for your investment`
        ],
    },
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Which companies should Hire UX researchers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Companies across a wide range of industries can benefit by hiring experienced user experience research consultants. 
          
          E-commerce businesses, SaaS providers, mobile app developers, fintech firms, healthcare organizations, enterprise software companies, and startups can all leverage the expertise of UX research consultants to offer exceptional digital experiences to users.
          
          UX research experts can help enhance product intuitiveness, create seamless user experiences, design user-centric solutions, and simplify complex interfaces by leveraging advanced research methodologies, data-driven insights, and a collaborative approach."
              }
            },{
              "@type": "Question",
              "name": "Why should I hire user experience researchers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our UX researchers bring a wealth of expertise and a proven track record for delivering impactful results. 
          
          With a team of seasoned user experience professionals, we provide data-driven insights to optimize your digital experiences, enhance usability, and drive measurable improvements in user satisfaction and conversion rates. 
          
          By hiring UX researchers from Octet, you can gain a competitive advantage, accelerate your time-to-market, and maximize the ROI of your design investments."
              }
            },{
              "@type": "Question",
              "name": "What are the steps to Hire UX researcher consultant from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can hire UX researchers in 3 easy steps:
          Share Your Requirement 
          Share your project needs and details with us, outlining your objectives and expectations so we can match you with the top UX researcher.
          Choose Your UX Research Consultant 
          Interview and hire UX researcher based on your business goals, assessing their experience, expertise, and compatibility.
          Get Started
          Finalize and onboard your chosen user experience research consultant and collaborate to implement effective user research strategies and achieve your desired outcomes."
              }
            },{
              "@type": "Question",
              "name": "How will I communicate with my UX researchers?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Once you onboard your hired UX researcher, you can communicate with them through various channels, such as email, phone calls, or video conferencing, for real-time conversations."
              }
            },{
              "@type": "Question",
              "name": "How much does it cost to hire user experience researchers from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We offer flexible pricing options for hiring our experienced user experience researchers, with costs varying based on factors like project duration, research activities, deliverables, and the level of expertise required. 
          
          We would request you to contact us to get a detailed cost estimate and to discuss specific requirements to ensure maximum value for your investment."
              }
            }]
          }                                                
        `}
      </script>
    </Helmet>
    <Layout hire="Hire UX Researchers" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire UX Researchers" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={'Discover some common questions on hiring a user experience researcher to improve user satisfaction and overall performance.'} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Hire UX Researchers | User Experience Research Consultant"
    description="Hire UX Researcher to enhance your user experience. Our UX Research Consultant delivers data-driven solutions to improve user satisfaction and engagement."
  />
);
